import $ from "jquery";


// Clear inputs on focus
$.fn.clearOnFocus = function () {
	return this.focus(function () {
		var v = $(this).val();
		$(this).val(v === this.defaultValue ? '' : v);
	}).blur(function () {
		var v = $(this).val();
		$(this).val(v.match(/^\s+$|^$/) ? this.defaultValue : v);
	});
};

$('input[type=text]').clearOnFocus();

var urlParams = new URLSearchParams(window.location.search)
window.languageCode = urlParams.get("lang") || "en";

function selectLink(option) {
	console.log('in select link ' + option);
	selected = option;
}
window.selectLink = selectLink;

$(document).ready(function () {
    $('#backToTopButton').click(function () {
        window.scrollTo({
            top: 0
        });
    });

	$('#hideCookieAcknowledgement').click(function () {
		$('.cookie-banner').hide();
	})
});
